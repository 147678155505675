.alice-wrapper > .featured-wrapper {
  border: 1px solid #cbcbcb;
  padding: 10px;
  border-radius: 5px;
}

.alice-wrapper > .featured-wrapper > img {
  object-fit: contain;
  height: 410px;
}

.alice-carousel__wrapper {
  margin-top: 25px;
}

.alice-carousel__stage {
  transition: none !important;
}

.alice-carousel__stage-item {
  max-height: 150px;
  box-sizing: border-box;
  height: 100%;
}

.alice-carousel__stage-item > div {
  margin: 0px;
}

.alice-carousel__stage-item > div > img {
  height: 130px;
  object-fit: contain;
}

.alice-carousel__dots,
.alice-carousel__prev-btn-wrapper,
.alice-carousel__next-btn-wrapper {
  display: none;
}
